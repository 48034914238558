<template>
  <PageListLayout noFilter>
    <template slot="header_right">
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="BaoCaoPic"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              BÁO CÁO HỆ THỐNG</v-list-item-title
            >
            <v-list-item-subtitle
              >Báo cáo hài lòng khách hàng</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-layout>
      </v-list-item>
      <div class="d-flex align-center">
        <v-autocomplete
          class="ml-8"
          v-model="formSearch.staff"
          :items="employers"
          label="Nhân viên phụ trách"
          item-text="name"
          item-value="id"
          outlined
          style="min-width: 300px"
          dense
          hide-details
        ></v-autocomplete>

        <v-select
          class="ml-8"
          style="min-width: 300px"
          v-model="formSearch.area"
          prepend-inner-icon="mdi-map-marker"
          :items="areasData"
          outlined
          dense
          item-text="name"
          item-value="id"
          label="khu vực"
          hide-details
        >
        </v-select>

        <v-btn class="ml-8" color="success" dark @click="reset">
          <v-icon dark left> mdi-reload </v-icon>
          Reset
        </v-btn>
        <v-btn class="ml-8" color="primary" dark @click="roleback">
          <v-icon dark left> mdi-arrow-left </v-icon>
          Quay lại
        </v-btn>
      </div>
    </template>
    <v-row>
      <v-card class="content">
        <!-- <v-tabs
          align-with-title
          class="tabs"
          centered
          color="#ffffff"
          background-color="rgb(0,150,136)"
        > -->
          <!-- <v-tab> Báo cáo hài lòng khách hàng</v-tab> -->
          <!-- <v-tab-item> -->
            <chart :target="data" />
          <!-- </v-tab-item> -->
          <!--
          <v-tab> Báo cáo thị phần 2 </v-tab>
          <v-tab-item>
            Component Báo cáo
          </v-tab-item> -->
        <!-- </v-tabs> -->
      </v-card>
    </v-row>
  </PageListLayout>
</template>

<script>
import BaoCaoPic from "../../../assets/images/baocao.svg";
import chart from "./Chart.vue";
import { getAllAreas } from "@/api/khuvuc";
import Report from "@/api/report.js";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
export default {
  components: { chart },
  data: () => ({
    BaoCaoPic,
    tab: null,
    employers: [],
    areasData: [],
    formSearch: {
      staff: "",
      area: "",
    },
    data: {},
  }),
  created() {
    this.getdata(this.formSearch), this.getAllArea(), this.getAllEmployee();
  },
  methods: {
    roleback() {
      this.$router.push("/baocao");
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllArea() {
      let data = await getAllAreas();
      this.areasData = data;
    },
    async getdata(val) {
      try {
        let res = await Report.reportBusinessResearch(val);
        this.data = null;
        this.data = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    reset() {
      this.formSearch.area = "";
      this.formSearch.staff = "";
    },
  },
  watch: {
    formSearch: {
      handler: function () {
        this.getdata(this.formSearch);
      },
      deep: true,
    },
  },
};
</script>
<style>
.content {
  width: 100%;
  min-height: 730px;
}
</style>