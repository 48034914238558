<template>
  <div class="container">
    <div id="chart">
      <apexchart
        :key="`chart-key-${key}`"
        type="bar"
        height="650"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
// import { reportBusiness } from "@/api/report.js";
export default {
  props: {
    target: {},
  },
  data() {
    return {
      key: 0,
      data: {},
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Số lượng",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " khách hàng";
            },
          },
        },
      },
      name:[],
    };
  },
  methods: {
    renderData() {
      // let name = [];
      let lessOne = [];
      let toOneFromThree = [];
      let toThreeFromSeven = [];
      let thanSeven = [];
      this.series = [];
      this.chartOptions.xaxis.categories = [];
      this.target.forEach((e) => {
        if (e.staff.trim() === "gói") {
          this.chartOptions.xaxis.categories.push("Trọn gói" + "-" + e.area);
        } else {
          this.chartOptions.xaxis.categories.push(e.staff + "-" + e.area);
        }
        lessOne.push(e.less_one_year);
        toOneFromThree.push(e.to_one_from_three);
        toThreeFromSeven.push(e.to_three_from_seven);
        thanSeven.push(e.than_seven);
      });
      // this.chartOptions.xaxis.categories = categories;
      // console.log(object);
      // this.chartOptions.xaxis.categories.concat(name);
      // for(let i=0 ; i< name.length; i++){
      //   this.chartOptions.xaxis.categories.push(name[i]);
      // }
      this.series.push(
        {
          name: "Nhỏ hơn 1 năm",
          data: lessOne,
        },
        {
          name: "Từ 1 - 3 năm",
          data: toOneFromThree,
        },
        {
          name: "Từ 3 - 7 năm",
          data: toThreeFromSeven,
        },
        {
          name: "Lớn hơn 7 năm",
          data: thanSeven,
        }
      );
      this.key++;
    },
  },
  watch: {
    target: function () {
      return this.renderData();
    },
  },
};
</script>

<style>
.container {
  margin-top: 10px;
  width: 100%;
}
</style>
